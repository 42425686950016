import { useRef, useState } from 'react';

import getSecondsFromUnixTime from './get-seconds-from-unix-time';

const useTimer = (initialValue: number) => {
  const [timer, setTimer] = useState<number>(initialValue);
  const timerRef = useRef<number>();

  const startTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    const id = window.setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(timerRef.current);
        }
        return prevTimer - 1;
      });
    }, 1000);
    timerRef.current = id;
  };

  const resetTimer = (unixTime: number) => {
    const seconds = getSecondsFromUnixTime(unixTime);
    setTimer(seconds);
    startTimer();
  };

  return { timer, resetTimer };
};

export default useTimer;
