import { pickFields } from '@/utils';

import type {
  IOrdersResult,
  IPaymentsResult,
  IRegistriesSelectOption,
  IWorkersResult,
  OrdersPreviewFields,
  PaymentsPreviewFields,
  RegistriesField,
  RegistriesListFields,
  RegistriesStatuses,
  RegistriesType,
  RegistriesTypeData,
  WorkersPreviewFields,
} from './types';

export const registriesNames: { [K in RegistriesType]: string } = {
  workers: 'Исполнители',
  orders: 'Заказы',
  payments: 'Платежи',
};

export const registriesStatuses: Record<RegistriesStatuses, string> = {
  pending: 'В обработке...',
  success: 'Проведен',
  error: 'Ошибка',
  warning: 'Частично проведен',
};

export const registryStatusSelects: {
  value: RegistriesStatuses;
  label: string;
}[] = Object.entries(registriesStatuses).map(([value, label]) => ({
  value: value as RegistriesStatuses,
  label,
}));

export const registryTypeSelects: IRegistriesSelectOption[] = (
  Object.keys(registriesNames) as RegistriesType[]
).map((key) => ({
  label: registriesNames[key],
  value: key,
}));

export const registryFields: Record<RegistriesType, RegistriesField[]> = {
  workers: pickFields<RegistriesTypeData<IWorkersResult>['workers']>({
    inn: true,
    phone: true,
  }),
  orders: pickFields<RegistriesTypeData<IOrdersResult>['orders']>({
    workplaceId: true,
    customName: true,
    salaryPerJob: true,
    start: true,
    finish: true,
    startTime: true,
    finishTime: true,
    breakTime: true,
    salaryType: true,
    max: true,
    salaryPerUnit: true,
    unit: true,
    onlyForOwn: true,
    amount: true,
    comment: true,
  }),
  payments: pickFields<RegistriesTypeData<IPaymentsResult>['payments']>({
    workplaceId: true,
    customName: true,
    salaryPerJob: true,
    start: true,
    finish: true,
    startTime: true,
    finishTime: true,
    breakTime: true,
    salaryType: true,
    max: true,
    salaryPerUnit: true,
    unit: true,
    onlyForOwn: true,
    amount: true,
    comment: true,
  }),
};

export const registriesFieldsDescriptions: Record<
  RegistriesListFields,
  string
> = {
  id: '№',
  createdAt: 'Дата создания',
  status: 'Статус',
  company: 'Компания',
  type: 'Тип',
  statistics: 'Статистика',
  uploaded: 'Загрузил',
  download: 'Файл реестра',
};

export const registriesWorkersFieldsDescriptions: Record<
  WorkersPreviewFields,
  string
> = {
  id: '№ строки',
  phone: 'Телефон',
  inn: 'ИНН',
};

export const ordersFieldsDescriptions: Record<OrdersPreviewFields, string> = {
  workplaceId: 'ID точки',
  customName: 'Название профессии',
  start: 'Дата начала',
  finish: 'Дата окончания',
  startTime: 'Время начала',
  finishTime: 'Время окончания',
  breakTime: 'Перерыв',
  amount: 'Сколько нужно исполнителей',
  salaryPerJob: 'Сумма за смену',
  onlyForOwn: 'Только для своих',
  salaryType: 'Расчет оплаты',
  unit: 'Единица измерения',
  max: 'Макс сделка (кол-во)',
  salaryPerUnit: 'Ставка за шт',
  comment: 'Комментарий',
};

export const paymentsFieldsDescriptions: Record<PaymentsPreviewFields, string> =
  {
    ...ordersFieldsDescriptions,
  };
