/**
 * Возвращает значение CSS-переменной
 * @param variableName Имя CSS-переменной
 * @returns Значение CSS-переменной или пустую строку, если переменная не найдена
 */
export default function getCssVariable(variableName: string): string {
  if (typeof window === 'undefined' || !document.documentElement) {
    return '';
  }

  return getComputedStyle(document.documentElement)
    .getPropertyValue(`${variableName}`)
    .trim();
}
