/* eslint-disable no-extend-native, no-underscore-dangle */

if (!window.__AVATARS_COLORS__) {
    window.__AVATARS_COLORS__ = new Map()
}

if (!Set.random) {
    Set.prototype.random = function random() {
        const index = Math.floor(Math.random() * this.size)

        return Array.from(this.keys())[index]
    }
}
