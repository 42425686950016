import { useEffect } from 'react';

type EventType = MouseEvent | TouchEvent;

/**
 * Хук для обработки кликов вне указанного элемента.
 *
 * @param ref - Ссылка на DOM-элемент.
 * @param handler - Функция-обработчик события.
 * @param when - Условие, при котором хук активен.
 */
function useClickOutside(
  ref: React.RefObject<HTMLElement>,
  handler: (event: EventType) => void,
  when: boolean = true,
): void {
  useEffect(() => {
    if (!when) return;

    const listener = (event: EventType) => {
      const target = event.target as HTMLElement;

      if (target.hasAttribute('data-like-outer-click')) {
        handler(event);
        return;
      }

      if (!ref.current || ref.current.contains(target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, when]);
}

export default useClickOutside;
