import { create } from 'zustand';

interface IUseSelectsDefault {
  companyDefault: string | undefined;
  setCompanyDefault: (companyDefault: string | undefined) => void;
  typeDefault: string | undefined;
  setTypeDefault: (typeDefault: string | undefined) => void;
}

export const useSelectsDefault = create<IUseSelectsDefault>((set) => ({
  companyDefault: undefined,
  setCompanyDefault: (companyDefault) => set({ companyDefault }),
  typeDefault: undefined,
  setTypeDefault: (typeDefault) => set({ typeDefault }),
}));
