import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type IBurgerState = {
  isOpen: boolean;
  burgerWidth: string;
  toggleBurger: (newState?: boolean) => void;
};

export const useBurgerState = create<IBurgerState>()(
  persist(
    (set) => ({
      isOpen: false,
      burgerWidth: 'var(--burger-width-close)',
      toggleBurger: (newState?: boolean) => {
        set((state) => {
          const isOpen = newState !== undefined ? newState : !state.isOpen;
          const burgerWidth =
            isOpen ? 'var(--burger-width-open)' : 'var(--burger-width-close)';
          return { isOpen, burgerWidth };
        });
      },
    }),
    {
      name: 'burger',
      getStorage: () => localStorage,
    },
  ),
);
