import { io } from 'socket.io-client';
import { create } from 'zustand';

import { baseURL } from '@/controllers/api';

import type { NamespaceSocket, SocketNs, SocketStore } from './types';

const useSocket = create<SocketStore>((set, get) => ({
  registry: null,
  init: <Ns extends SocketNs>(namespace: Ns) => {
    // close previous socket if exists
    get()[namespace]?.close();

    const socket = io(`${baseURL.replace(/^http/, 'ws')}/${namespace ?? ''}`, {
      path: '/socket.io',
      transports: ['websocket', 'polling'],
      auth: {
        token: `Bearer ${localStorage.getItem('access')}`,
      },
    });

    set({ [namespace]: socket });

    return socket as NamespaceSocket<Ns>;
  },
}));

export default useSocket;
