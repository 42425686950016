import { z } from 'zod';
import { toFormikValidationSchema as zodAdapter } from 'zod-formik-adapter';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailValidationSchema = zodAdapter(
  z.object({
    login: z
      .string({
        required_error: 'Введите адрес электронной почты',
        invalid_type_error: 'Ожидается строка с адресом эл. почты',
      })
      .regex(EMAIL_REGEX, 'Некорректный формат эл. почты'),
  }),
);

const phoneValidationSchema = zodAdapter(
  z.object({
    login: z
      .string({
        required_error: 'Введите номер телефона',
        invalid_type_error: 'Ожидается строка с номером телефона',
      })
      .regex(/^[0-9]+$/, 'Только цифры')
      .min(11, 'Номер введён не до конца')
      .max(16, 'Номер слишком длинный'), // никогда такого не будет, но пускай
  }),
);

const codeValidationSchema = zodAdapter(
  z.object({
    code: z
      .number({
        required_error: 'Обязательное поле',
        invalid_type_error: 'Ожидается код',
      })
      .min(1000, 'Введите 4 цифры кода')
      .max(9999, 'Введите 4 цифры кода'),
  }),
);

export { emailValidationSchema, phoneValidationSchema, codeValidationSchema };
