import { create } from 'zustand';

interface IUseMediaState {
  isMobile: boolean;
  setIsMobile: (value: boolean) => void;
  isTablet: boolean;
  setIsTablet: (value: boolean) => void;
  isLaptop: boolean;
  setIsLaptop: (value: boolean) => void;
  isDesktop: boolean;
  setIsDesktop: (value: boolean) => void;
}

export const useMediaState = create<IUseMediaState>((set) => ({
  isMobile: false,
  setIsMobile: (value) => set({ isMobile: value }),
  isTablet: false,
  setIsTablet: (value) => set({ isTablet: value }),
  isLaptop: false,
  setIsLaptop: (value) => set({ isLaptop: value }),
  isDesktop: false,
  setIsDesktop: (value) => set({ isDesktop: value }),
}));
