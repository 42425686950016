import { useEffect } from 'react';

const redirectToSavedPath = () => {
  const savedPath = localStorage.getItem('redirectPath');
  if (savedPath && window.location.pathname !== '/') {
    localStorage.removeItem('redirectPath');
    window.location.href = savedPath;
  }
};

const useRedirectToSavedPath = () => {
  useEffect(() => {
    redirectToSavedPath();
  }, []);
};

export default useRedirectToSavedPath;
