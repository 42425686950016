import { create } from 'zustand';

interface IUseRegistriesModal {
  isOpenInstructions: boolean;
  setIsOpenInstructions: (value: boolean) => void;
  isOpenLoadRegistriesStepOne: boolean;
  setIsOpenLoadRegistriesStepOne: (value: boolean) => void;
  isOpenLoadRegistriesStepTwo: boolean;
  setIsOpenLoadRegistriesStepTwo: (value: boolean) => void;
}

export const useRegistriesModal = create<IUseRegistriesModal>((set) => ({
  isOpenInstructions: false,
  setIsOpenInstructions: (value) => set({ isOpenInstructions: value }),
  isOpenLoadRegistriesStepOne: false,
  setIsOpenLoadRegistriesStepOne: (value) =>
    set({ isOpenLoadRegistriesStepOne: value }),
  isOpenLoadRegistriesStepTwo: false,
  setIsOpenLoadRegistriesStepTwo: (value) =>
    set({ isOpenLoadRegistriesStepTwo: value }),
}));
