import type { TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';

export const RANGES: TimeRangePickerProps['presets'] = [
  { label: 'Сегодня', value: [dayjs(), dayjs()] },
  {
    label: 'На прошлой неделе',
    value: [
      dayjs().startOf('week').subtract(1, 'week'),
      dayjs().endOf('week').subtract(1, 'week'),
    ],
  },
  {
    label: 'На этой неделе',
    value: [dayjs().startOf('week'), dayjs().endOf('week')],
  },
  {
    label: 'В этом месяце',
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  },
];

export const STATUSES = new Map([
  ['active', 'Активен'],
  ['inactive', 'Завершён'],
  ['deleted', 'Отменён'],
]);

export const STATUS_OPTIONS: {
  value: string;
  label: string;
}[] = Array.from(STATUSES).map(([value, label]) => ({
  value,
  label,
}));

export const JOB_STATUSES = new Map([
  ['booked', 'Забронирована'],
  ['doccheck', 'На проверке документов'],
  ['going', 'В пути'],
  ['delayed', 'Опаздывает'],
  ['waiting', 'Ожидается'],
  ['inprogress', 'Выполняется'],
  ['completed', 'Завершена'],
  ['confirmed', 'Подтверждена'],
  ['checkingin', 'Нет чекина в начале'],
  ['checkingout', 'Нет чекина в конце'],
  ['toolate', 'Отменена из-за опоздания'],
  ['cancelled', 'Отменена'],
  ['expired', 'Просрочена'],
  ['failed', 'Не выполнена'],
]);

export const JOB_STATUSES_OPTIONS: {
  value: string;
  label: string;
}[] = Array.from(JOB_STATUSES).map(([value, label]) => ({
  value,
  label,
}));

export const CONTRACT_TYPES = new Map([
  ['saas', 'Платформа'],
  ['services', 'Платформа +'],
  ['processing', 'Платформа PRO'],
]);

export const CONTRACT_TYPES_OPTIONS: {
  value: string;
  label: string;
}[] = Array.from(CONTRACT_TYPES).map(([value, label]) => ({
  value,
  label,
}));

export const PAYMENT_STATUSES = new Map([
  ['new', 'В ожидании'],
  ['synced', 'В обработке в 1C'],
  ['bank_init', 'Ожидание'],
  ['bank_processed', 'Проводится банком'],
  ['done', 'Проведён'],
  ['bank_done', 'Проведён банком'],
  ['rejected', 'Отклонён'],
  ['bank_rejected', 'Отклонён банком'],
]);

export const PAYMENT_STATUSES_OPTIONS: {
  value: string;
  label: string;
}[] = Array.from(PAYMENT_STATUSES).map(([value, label]) => ({
  value,
  label,
}));
