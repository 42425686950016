const phoneFormatter = (s?: string) => {
  if (!s) return '';
  const raw = s.replace(/\D/g, '');

  let fm = '+7';
  if (raw.length > 1) {
    fm += ' (';
    fm += raw.slice(1, 4);
    if (raw.length > 4) {
      fm += ') ';
      fm += raw.slice(4, 7);
      if (raw.length > 7) {
        fm += '-';
        fm += raw.slice(7, 9);
        if (raw.length > 9) {
          fm += '-';
          fm += raw.slice(9);
        }
      }
    }
  }

  return fm;
};

export default phoneFormatter;
