import { ROUTES } from '@/constants/router.constants';

export const roleConfig = {
  worker: {
    routes: [],
  },
  employer: {
    routes: [
      ROUTES.ORDERS.INDEX,
      ROUTES.BALANCE.INDEX,
      ROUTES.REGISTRIES.INDEX,
      ROUTES.WORKERS.INDEX,
      ROUTES.REPORTS.INDEX,
    ],
  },
  supervisor: {
    routes: [
      ROUTES.ORDERS.INDEX,
      ROUTES.BALANCE.INDEX,
      ROUTES.REGISTRIES.INDEX,
      ROUTES.WORKERS.INDEX,
      ROUTES.REPORTS.INDEX,
    ],
  },
  partner: {
    routes: [ROUTES.ORDERS.INDEX],
  },
};
