import { Outlet } from 'react-router-dom';

import { Aside, Header } from '@src/widgets/';
import useMediaObserver from '@/hooks/media/useMediaObserver';
import useRedirectToSavedPath from '@/hooks/redirect/useRedirectToSavedPath';

import css from './css.module.scss';

interface AppLayoutProps {
  hasError?: boolean;
}

const AppLayout = ({ hasError }: AppLayoutProps) => {
  useRedirectToSavedPath();
  useMediaObserver();
  return (
    <>
      <div className={css.container}>
        <Aside />
        <div className={css.content}>
          <Header />
          <div className={css.routes}>
            {hasError && (
              <div className={css.error}>
                Что то пошло не так, попробуйте перезагрузить страницу
              </div>
            )}
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
