import { useLayoutEffect } from 'react';
import { useMediaQuery } from '@uidotdev/usehooks';

import { getCssVariable } from '@/utils';

import { useMediaState } from './useMediaState';

export default function useMediaObserver() {
  const { setIsMobile, setIsTablet, setIsLaptop, setIsDesktop } =
    useMediaState();

  const isMobile = useMediaQuery(
    `(max-width:${getCssVariable('--mobile-max')})`,
  );

  const isTablet = useMediaQuery(
    `(min-width:${getCssVariable('--mobile-min')}) and (max-width:${getCssVariable('--tablet-max')})`,
  );

  const isLaptop = useMediaQuery(
    `(min-width:${getCssVariable('--tablet-min')}) and (max-width:${getCssVariable('--laptop-max')})`,
  );

  const isDesktop = useMediaQuery(
    `(min-width:${getCssVariable('--laptop-min')})`,
  );

  useLayoutEffect(() => {
    setIsMobile(isMobile);
    setIsTablet(isTablet);
    setIsLaptop(isLaptop);
    setIsDesktop(isDesktop);
  }, [
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    setIsMobile,
    setIsTablet,
    setIsLaptop,
    setIsDesktop,
  ]);
}
