/**
 * Сохраняет текущий путь для последующего редиректа.
 * Сохраняется:
 * 1. При переходе по URL без авторизации.
 * 2. При потере авторизации.
 */
export const saveRedirectPath = () => {
  const savedPath = localStorage.getItem('redirectPath');
  if (!savedPath) {
    const currentPath = window.location.pathname + window.location.search;
    if (currentPath && currentPath !== '/') {
      localStorage.setItem('redirectPath', currentPath);
    }
  }
};

export default saveRedirectPath;
