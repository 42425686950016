import { RouterProvider } from 'react-router-dom';
import { useMemo } from 'react';

import { useRegistriesSocket } from '@src/pages';
import { useProfile } from '@src/entities/user/queries';
import type { UserRole } from '@src/entities/user';

import router from './router';

export default function Router() {
  useRegistriesSocket();

  const { data: profile } = useProfile();
  const role = profile?.role;
  const memoizedRouter = useMemo(() => router(role as UserRole), [role]);

  return <RouterProvider router={memoizedRouter} />;
}
