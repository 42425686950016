import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import type { ReactNode } from 'react';

import 'react-material-symbols/rounded';
import { AntTheme } from '@/styles/ant-theme';

import '@/styles/index.scss';
import 'antd-mobile/es/global';

export default function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <ConfigProvider theme={AntTheme} locale={locale}>
      {children}
    </ConfigProvider>
  );
}
