import type { ReactNode } from 'react';

import { useProfile } from '@src/entities/user/queries';
import ErrorPage from '@src/pages/error-page/ui';
import Loader from '@/ui/loader';
import Login from '@src/modules/login';
import { ROUTES } from '@/constants/router.constants';

export default function AuthGuard({ children }: { children: ReactNode }) {
  const { data: profile, isPending, error } = useProfile();

  if (isPending) {
    return <Loader />;
  }

  if (!profile) {
    if (location.pathname !== `${ROUTES.INDEX}`) {
      return <ErrorPage networkError={error} />;
    }
    return <Login />;
  }

  return <>{children}</>;
}
