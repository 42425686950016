import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';

import App from './app/App';

const GLITCHTIP_DSN = import.meta.env.REACT_APP_GLITCHTIP_DSN;

if (GLITCHTIP_DSN) {
  Sentry.init({
    dsn: GLITCHTIP_DSN,
  });
}

const mountNode = document.getElementById('root');
window.__MOUNT_NODE__ = mountNode;

const root = createRoot(mountNode);
root.render(App());
