import css from './styles.module.scss';

const Loader = () => {
  return (
    <div className={css.container}>
      <div className={css.loaderLineMask}>
        <div className={css.loaderLine}></div>
      </div>
    </div>
  );
};

export default Loader;
