import { useCallback, useEffect, useRef } from 'react';
import type { QueryKey } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import message from 'antd/es/message';

import useSocket from '@src/features/socket';
import { useUploadState } from '@src/features/registries';
import type { Registries } from '@src/entities/registries';
import type {
  IRegistryException,
  IRegistryResponse,
  registriesSocketType,
} from '@src/features/socket/types';
import type { QueryCache } from '@/types/react-query-types';

export const useRefreshItemsSocket = <T extends Registries>() => {
  const queryClient = useQueryClient();
  const socketInit = useSocket((s) => s.init);
  const { registriesSendResponse, setRegistriesSendResponse } =
    useUploadState();
  const registriesId = registriesSendResponse?.id ?? null;
  const connectionRef = useRef<registriesSocketType | null>(null);
  const previousRegistriesIdRef = useRef<string | null>(null);

  const handleChangeCache = useCallback(
    (searchedQueryKey: QueryKey, serverResponse: IRegistryResponse) => {
      const queriesData = queryClient.getQueriesData({
        queryKey: searchedQueryKey,
      });

      for (const [queryKey, data] of queriesData) {
        const myData = data as QueryCache<T>;

        const index = myData.items.findIndex(
          (item) => item.id === serverResponse.id,
        );
        if (index !== -1) {
          queryClient.setQueryData(queryKey, (oldData: QueryCache<T>) => ({
            ...oldData,
            items: oldData.items.map((item, i) =>
              i === index ?
                {
                  ...item,
                  status: serverResponse.status,
                  successRows: serverResponse.successRows,
                  countRows: serverResponse.countRows,
                }
              : item,
            ),
          }));
          break;
        }
      }
    },
    [queryClient],
  );

  useEffect(() => {
    const onMessage = (
      serverResponse: IRegistryResponse,
      connection: registriesSocketType,
    ) => {
      const unknownResponse = serverResponse as unknown as string;
      if (unknownResponse.includes('subscribed')) return;

      const response = JSON.parse(
        serverResponse as unknown as string,
      ) as IRegistryResponse;

      if (response.id === registriesId) {
        handleChangeCache(['registries', 'list'], response);
        connection?.emit('unsubscribeRegistry', { id: registriesId });
        connection?.removeAllListeners('exception');
        connection?.removeAllListeners('message');
        connection?.close();
        setRegistriesSendResponse(response);

        if (response.status === 'success') {
          connectionRef.current = null;
        }
      }
    };

    const onException = (data: IRegistryException) => {
      const response = JSON.parse(
        data as unknown as string,
      ) as IRegistryException;
      if (response.statusCode !== 500) {
        void message.error(response.message);
      }
    };

    if (
      registriesId &&
      !connectionRef.current &&
      registriesId !== previousRegistriesIdRef.current
    ) {
      const connection = socketInit('registry');
      connectionRef.current = connection;

      const messageCallback = (socketResponse: IRegistryResponse) => {
        if (socketResponse && connectionRef.current) {
          onMessage(socketResponse, connectionRef.current);
        }
      };

      connection?.removeAllListeners('exception');
      connection?.removeAllListeners('message');
      connection.on('exception', onException);
      connection.on('message', messageCallback);
      return () => {
        if (connectionRef.current) {
          connectionRef.current.off('message', messageCallback);
          connectionRef.current.off('exception', onException);
          connectionRef.current.close();
          connectionRef.current = null;
        }
      };
    }
  }, [
    registriesId,
    socketInit,
    queryClient,
    handleChangeCache,
    registriesSendResponse,
    setRegistriesSendResponse,
  ]);

  useEffect(() => {
    if (
      registriesId &&
      connectionRef.current &&
      registriesId !== previousRegistriesIdRef.current
    ) {
      previousRegistriesIdRef.current = registriesId;
      connectionRef.current.emit('subscribeRegistry', { id: registriesId });
    }
  }, [registriesId]);
};
