import { Fragment, useCallback, useMemo } from 'react';
import type { ReactElement, ReactNode } from 'react';
import { Link, useMatches, useNavigate } from 'react-router-dom';
import Icon from 'feather-react';
import type { Params } from 'react-router-dom';

import css from './styles.module.scss';

interface IMatches {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: unknown;
}

interface BreadcrumbLinkProps {
  to: string;
  name: string;
}

interface BreadcrumbsProps {
  crumbToMobileHeader?: boolean;
}

export default function Breadcrumbs({ crumbToMobileHeader }: BreadcrumbsProps) {
  type HandleType = {
    crumb: (param?: string) => ReactNode;
  };

  const matches: IMatches[] = useMatches();
  const navigate = useNavigate();

  const crumbs = useMemo(() => {
    return matches
      .filter((match) =>
        Boolean(match.handle && (match.handle as HandleType).crumb),
      )
      .map((match) => {
        const crumb = (match.handle as HandleType).crumb(
          match.data as string | undefined,
        );
        return crumb as ReactElement<BreadcrumbLinkProps>;
      });
  }, [matches]);

  const handleBackClick = useCallback(() => {
    if (crumbs.length > 1) {
      const previousCrumb = crumbs[crumbs.length - 2];
      navigate(previousCrumb.props.to);
    }
  }, [crumbs, navigate]);

  return (
    <div className={css.crumbs}>
      {crumbToMobileHeader ?
        <div className={css.mobileHeader}>
          {crumbs.length > 1 && (
            <>
              <Icon
                onClick={handleBackClick}
                className={css.backButton}
                size={24}
              >
                arrow-left
              </Icon>
              <div className={css.title}>
                {crumbs.map((crumb, index) => {
                  const isLastCrumb = !(index < crumbs.length - 1);
                  const { name } = crumb.props;
                  return <>{isLastCrumb && <span>{name}</span>}</>;
                })}
              </div>

              <div></div>
            </>
          )}
        </div>
      : <>
          {crumbs.length > 1 && (
            <Icon
              onClick={handleBackClick}
              className={css.backButton}
              size={20}
            >
              arrow-left
            </Icon>
          )}
          {crumbs.map((crumb, index) => {
            const isLastCrumb = !(index < crumbs.length - 1);
            const { to, name } = crumb.props;
            return (
              <Fragment key={to}>
                <div className={css.crumb}>
                  {isLastCrumb ?
                    <span>{name}</span>
                  : <Link to={to}>{name}</Link>}
                </div>
                {!isLastCrumb && <span className={css.separator}>&gt;</span>}
              </Fragment>
            );
          })}
        </>
      }
    </div>
  );
}
