import type { ApiError } from '@/api/types';

import css from './styles.module.scss';

interface IErrorPageProps {
  networkError?: ApiError | null;
  handleBoundary?: boolean;
}

function ErrorPage({ networkError, handleBoundary }: IErrorPageProps) {
  return (
    <div className={css.container}>
      {!handleBoundary && networkError && (
        <div className={css.network}>
          <p>Произошла ошибка при получении данных</p>

          <p>Мы скоро всё исправим. Пожалуйста, обновите страницу позже.</p>
          <p>
            {networkError?.response?.status && (
              <>Статус ошибки: {networkError?.response?.status}</>
            )}
          </p>
        </div>
      )}

      {handleBoundary && (
        <div className={css.boundary}>
          <p>Что то пошло не так, попробуйте перегрузить страницу</p>
        </div>
      )}
    </div>
  );
}

export default ErrorPage;
