import { create } from 'zustand';

interface IUseWorkersModal {
  isOpenAddWorkers: boolean;
  setIsOpenAddWorkers: (value: boolean) => void;
  isOpenShare: boolean;
  setIsOpenShare: (value: boolean) => void;
}

export const useWorkersModal = create<IUseWorkersModal>((set) => ({
  isOpenAddWorkers: false,
  setIsOpenAddWorkers: (value) => set({ isOpenAddWorkers: value }),
  isOpenShare: false,
  setIsOpenShare: (value) => set({ isOpenShare: value }),
}));
