import { Link } from 'react-router-dom';

import css from './styles.module.scss';

interface BreadcrumbLinkProps {
  to: string;
  name: string;
}

export default function BreadcrumbLink({ to, name }: BreadcrumbLinkProps) {
  return (
    <Link to={to} className={css.link}>
      {name}
    </Link>
  );
}
