import type { User, UserRole } from '@src/entities/user';

export function getFullName(user: User) {
  let fullName = '';

  if (user?.lastname) {
    fullName += `${user.lastname} `;
  }

  if (user.firstname) {
    fullName += `${user.firstname} `;
  }

  if (user.secondname) {
    fullName += `${user.secondname}`;
  }

  return fullName;
}

export function getRole(role: UserRole) {
  switch (role) {
    case 'employer':
      return 'заказчик';

    case 'worker':
      return 'исполнитель';

    case 'supervisor':
      return 'управляющий';

    case 'partner':
      return 'партнер';

    default:
      // eslint-disable-next-line no-case-declarations
      const caseGuard: never = role;
      return caseGuard;
  }
}
