import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type {
  IUploadFileResponse,
  SendRegistriesResponse,
} from '@src/entities/registries';

interface IUseUploadState {
  registriesUploadResponse: IUploadFileResponse | null;
  setRegistriesUploadResponse: (response: IUploadFileResponse | null) => void;

  registriesSendResponse: SendRegistriesResponse | null;
  setRegistriesSendResponse: (response: SendRegistriesResponse | null) => void;

  resetUploadState: () => void;
}

export const useUploadState = create<IUseUploadState>()(
  persist(
    (set) => ({
      registriesUploadResponse: null,
      setRegistriesUploadResponse: (registriesUploadResponse) =>
        set({ registriesUploadResponse }),

      registriesSendResponse: null,
      setRegistriesSendResponse: (registriesSendResponse) =>
        set({ registriesSendResponse }),

      resetUploadState: () =>
        set({
          registriesUploadResponse: null,
          registriesSendResponse: null,
        }),
    }),
    {
      name: 'upload-state',
      getStorage: () => sessionStorage,
      partialize: (state) => ({
        registriesUploadResponse: state.registriesUploadResponse,
      }),
    },
  ),
);
