import AuthGuard from './providers/AuthGuard';
import Router from './providers/Router';
import ThemeProvider from './providers/ThemeProvider';
import ErrorBoundary from './providers/ErrorBoundary';
import DayjsProvider from './providers/DayjsProvider';
import QueryClientProvider from './providers/QueryClientProvider';

import '@/polyfill';

export default function App() {
  return (
    <ThemeProvider>
      <QueryClientProvider>
        <DayjsProvider>
          <ErrorBoundary>
            <AuthGuard>
              <Router />
            </AuthGuard>
          </ErrorBoundary>
        </DayjsProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
