import { useMemo, useRef } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import { usePoints } from '@src/entities/supervisor/queries';
import Icons from '@/icons';
import { onLogout, onVersionSwitch } from '@/controllers/auth';
import { REACT_APP_SUPPORT_PHONE } from '@/constants';
import type { RouterPathnames } from '@/constants/router.constants';
import { ROUTER_PATHNAMES, ROUTES } from '@/constants/router.constants';
import { CustomNavLink } from '@/lib/router/router-customnavlink';
import { useBurger } from '@src/features';
import { useProfile } from '@src/entities/user/queries';
import { roleConfig } from '@src/entities/user/lib/role-config';
import { useMediaState } from '@/hooks/media/useMediaState';
import { useClickOutside } from '@/hooks';

import css from './styles.module.scss';

const { REACT_APP_VERSION } = import.meta.env;

const Aside = () => {
  const { data: points, isFetching, error } = usePoints();

  const { data: profile } = useProfile();
  const role = profile?.role;

  // Доп условие доступности раздела "Баланс"
  const workplaces = points?.workplaces;
  const isDepositEnabled = useMemo(
    () =>
      workplaces &&
      workplaces?.findIndex(
        (workplace) => workplace.contractor?.is_deposit_enabled,
      ) > -1,
    [workplaces],
  );
  const isBalanceVisible = isFetching || (!error && isDepositEnabled);

  const asideLinks = roleConfig[role!].routes;

  const { isOpen, toggleBurger, burgerWidth: width } = useBurger();
  const { isMobile } = useMediaState();
  const burgerWidth = isMobile ? 'var(--burger-width-open)' : width;

  const asideRef = useRef<HTMLDivElement>(null);
  useClickOutside(
    asideRef,
    () => {
      toggleBurger(false);
    },
    isMobile && isOpen,
  );

  const handleClickLink = () => {
    isMobile && isOpen && toggleBurger(false);
  };

  const location = useLocation();

  return (
    <aside
      ref={asideRef}
      className={cn(css.container, {
        [css.containerMobileOpen]: isMobile && isOpen,
        [css.containerMobileClosed]: isMobile && !isOpen,
      })}
      style={{
        width: burgerWidth,
      }}
    >
      <div className={css.header}>
        <div className={isOpen ? css.burgerLogo : css.burgerLogoHidden}>
          <img
            src="/assets/images/aside/mygig-icon.svg"
            alt={REACT_APP_VERSION}
            title={REACT_APP_VERSION}
          />
          <p>MyGig</p>
        </div>
        {/* //TODO чет z-index на layout не скрывает его */}
        {!location.pathname.includes('/v/') && (
          <div
            className={isOpen ? css.burgerArrow : css.burgerArrowRotate}
            onClick={() => toggleBurger()}
            style={isMobile ? { display: 'none' } : {}}
          >
            {Icons.burgerArrow}
          </div>
        )}
      </div>

      <div className={css.linksContainer}>
        <div className={css.body}>
          <div className={isOpen ? css.links : css.linksHidden}>
            {asideLinks.map((link) => {
              switch (link) {
                case ROUTES.ORDERS.INDEX:
                  return (
                    <CustomNavLink
                      key={link}
                      to={`/${ROUTES.ORDERS.INDEX}/${ROUTES.ORDERS.LIST}`}
                      activePath={`/${ROUTES.ORDERS.INDEX}`}
                      className={css.link}
                      cssModule={css}
                      onClick={handleClickLink}
                    >
                      {Icons.orders}
                      <span>
                        {
                          ROUTER_PATHNAMES[
                            ROUTES.ORDERS.INDEX as RouterPathnames
                          ]
                        }
                      </span>
                    </CustomNavLink>
                  );
                case ROUTES.BALANCE.INDEX:
                  return (
                    <CustomNavLink
                      key={link}
                      to={`/${ROUTES.BALANCE.INDEX}`}
                      activePath={`/${ROUTES.BALANCE.INDEX}`}
                      className={css.link}
                      cssModule={css}
                      visible={isBalanceVisible}
                      onClick={handleClickLink}
                    >
                      {Icons.rouble}
                      <span>
                        {
                          ROUTER_PATHNAMES[
                            ROUTES.BALANCE.INDEX as RouterPathnames
                          ]
                        }
                      </span>
                    </CustomNavLink>
                  );
                case ROUTES.REGISTRIES.INDEX:
                  return (
                    <CustomNavLink
                      key={link}
                      to={`/${ROUTES.REGISTRIES.INDEX}/${ROUTES.REGISTRIES.LIST}`}
                      activePath={`/${ROUTES.REGISTRIES.INDEX}`}
                      className={css.link}
                      cssModule={css}
                      onClick={handleClickLink}
                    >
                      {Icons.registries}
                      <span>
                        {
                          ROUTER_PATHNAMES[
                            ROUTES.REGISTRIES.INDEX as RouterPathnames
                          ]
                        }
                      </span>
                    </CustomNavLink>
                  );
                case ROUTES.WORKERS.INDEX:
                  return (
                    <CustomNavLink
                      key={link}
                      to={`/${ROUTES.WORKERS.INDEX}`}
                      activePath={`/${ROUTES.WORKERS.INDEX}`}
                      className={css.link}
                      cssModule={css}
                      onClick={handleClickLink}
                    >
                      {Icons.workers}
                      <span>
                        {
                          ROUTER_PATHNAMES[
                            ROUTES.WORKERS.INDEX as RouterPathnames
                          ]
                        }
                      </span>
                    </CustomNavLink>
                  );
                case ROUTES.REPORTS.INDEX:
                  return (
                    <CustomNavLink
                      key={link}
                      to={`/${ROUTES.REPORTS.INDEX}/${ROUTES.REPORTS.LIST}`}
                      activePath={`/${ROUTES.REPORTS.INDEX}`}
                      className={css.link}
                      cssModule={css}
                      onClick={handleClickLink}
                    >
                      {Icons.reports}
                      <span>
                        {
                          ROUTER_PATHNAMES[
                            ROUTES.REPORTS.INDEX as RouterPathnames
                          ]
                        }
                      </span>
                    </CustomNavLink>
                  );
                default:
                  return null;
              }
            })}
          </div>

          <div className={css.divider}></div>

          <div className={isOpen ? css.links : css.linksHidden}>
            <a href={`tel:${REACT_APP_SUPPORT_PHONE}`} className={css.link}>
              {Icons.support}
              <span>{REACT_APP_SUPPORT_PHONE}</span>
            </a>
            <a
              href="https://t.me/MyGigBot"
              target="_blank"
              rel="noreferrer"
              className={css.link}
            >
              {Icons.telegram}
              <span>Telegram</span>
            </a>
            <a
              href="https://helpbusiness.mygig.ru"
              target="_blank"
              rel="noreferrer"
              className={css.link}
            >
              {Icons.faq}
              <span>Справочник</span>
            </a>
          </div>

          <div className={css.divider}></div>

          <div className={isOpen ? css.links : css.linksHidden}>
            <span
              role="button"
              tabIndex={0}
              className={css.link}
              onClick={onVersionSwitch}
            >
              {Icons.mobile}
              <span>Мобильная версия</span>
            </span>

            <CustomNavLink
              to={`/${ROUTES.PLAYGROUND.INDEX}`}
              activePath={`/${ROUTES.PLAYGROUND.INDEX}`}
              className={css.link}
              cssModule={css}
              visible={import.meta.env.REACT_APP_ENV === 'development'}
              onClick={handleClickLink}
            >
              {Icons.help}
              <span>
                {ROUTER_PATHNAMES[ROUTES.PLAYGROUND.INDEX as RouterPathnames]}
              </span>
            </CustomNavLink>
            {isMobile && (
              <span
                role="button"
                tabIndex={0}
                className={css.link}
                onClick={onLogout}
              >
                {Icons.exit}
                <span>Выйти</span>
              </span>
            )}
          </div>
        </div>
      </div>

      <div className={css.footer}>
        <div className={isOpen ? css.links : css.linksHidden}>
          {!isMobile && (
            <span
              role="button"
              tabIndex={0}
              className={css.link}
              onClick={onLogout}
            >
              {Icons.exit}
              <span>Выйти</span>
            </span>
          )}
        </div>
      </div>
    </aside>
  );
};

export default Aside;
