import type { ReactNode } from 'react';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import cn from 'classnames';

interface CustomNavLinkProps {
  to: string;
  activePath?: string;
  className?: string;
  children: ReactNode;
  cssModule: {
    [key: string]: string;
  };
  visible?: boolean;
  onClick?: () => void;
}

export const CustomNavLink = ({
  to,
  activePath,
  className,
  children,
  cssModule,
  visible = true,
  onClick,
}: CustomNavLinkProps) => {
  const resolved = useResolvedPath(activePath || to);
  const match = useMatch({ path: resolved.pathname, end: false });

  if (!visible) {
    return null;
  }

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cn(className, {
          [cssModule.active]: isActive || Boolean(match),
        })
      }
      end={false}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};

export default CustomNavLink;
