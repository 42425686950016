const COLORS = {
  PRIMARY: '#F7CE17', //не работает обычная переменная 'var(--bg-yellow)'
  CONTAINER_BG: '#FAFAFA', // к некоторым  элементам - например к картинкам не применяется цвет в таком виде, но если использовать var - то в добавок все картинки получают fill с таким цветом
  BORDER: 'var(--border)',
  SPLIT_LINE: 'var(--split_line)',
  PLACEHOLDER: 'var(--placeholder)',
  ERROR_TEXT: 'var( --error_text)',
  // PRIMARY: '#F7CE17',
  // CONTAINER_BG: '#FAFAFA',
  // BORDER: '#d9d9d9',
  // SPLIT_LINE: '#d9d9d9',
  // PLACEHOLDER: '#bfbfbf',
  // ERROR_TEXT: '#b72025',
};

export const AntTheme = {
  token: {
    fontFamily: 'Roboto, sans-serif',
    primaryColor: COLORS.PRIMARY,
    colorPrimary: COLORS.PRIMARY,

    borderRadius: parseInt('8px', 10),
    colorBgContainer: COLORS.CONTAINER_BG,
    colorBorder: COLORS.BORDER,
    colorSplit: COLORS.SPLIT_LINE,
    colorTextPlaceholder: COLORS.PLACEHOLDER,
    colorErrorText: COLORS.ERROR_TEXT,
  },
};
