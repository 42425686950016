import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import type { ReactNode } from 'react';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.locale('ru');
dayjs.tz.setDefault('Europe/Moscow');

export default function DayjsProvider({ children }: { children: ReactNode }) {
  return children;
}
