import type { ReactNode } from 'react';
import { QueryClientProvider as ClientProvider } from '@tanstack/react-query';

import { queryClient } from '@/api/query-client';

const QueryClientProvider = ({ children }: { children: ReactNode }) => {
  return <ClientProvider client={queryClient}>{children}</ClientProvider>;
};

export default QueryClientProvider;
