export const ROUTES = {
  INDEX: '/',
  ORDERS: {
    INDEX: 'orders',
    LIST: 'list',
    EDIT: 'e',
    VIEW: 'v',
    NEW: 'new',
    RATES: 'rates',
  },
  BALANCE: {
    INDEX: 'balance',
  },
  REGISTRIES: {
    INDEX: 'registries',
    LIST: 'list',
    PREVIEW: 'preview',
  },
  WORKERS: {
    INDEX: 'workers',
  },
  REPORTS: {
    INDEX: 'reports',
    DOCS: 'docs',
    LIST: 'list',
  },
  PLAYGROUND: {
    INDEX: 'playground',
  },
  NOTFOUND: {
    INDEX: '*',
  },
};

export const ROUTER_PATHNAMES = {
  orders: 'Заказы',
  balance: 'Баланс',
  registries: 'Реестры',
  workers: 'Исполнители',
  playground: 'Playground',
  reports: 'Отчеты',
};

export type RouterPathnames = keyof typeof ROUTER_PATHNAMES;

export const ROUTER_BREADCRUMBS_PATHNAMES: Record<string, string> = {
  '/orders': ROUTER_PATHNAMES['orders'],
  '/orders/new': 'Новый заказ',
  '/orders/e/': 'Редактирование заказа',
  '/orders/rates': 'Увеличить ставку',
  '/balance': ROUTER_PATHNAMES['balance'],
  '/registries': ROUTER_PATHNAMES['registries'],
  '/registries/preview': 'Предпросмотр реестра',
  '/workers': ROUTER_PATHNAMES['workers'],
  '/reports': ROUTER_PATHNAMES['reports'],
  '/playground': ROUTER_PATHNAMES['playground'],
};

export const ROUTER_TABS: Record<string, string> = {
  '/reports/docs': 'Закрывающие документы',
  '/reports/list': 'Отчеты',
};
