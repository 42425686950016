import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';

import type { ApiError } from '@/api/types';
import Api from '@/controllers/api';
import { pick } from '@/utils';
import { uniqBy } from '@/utils/Helpers';

import type {
  EmployersResult,
  IEmployer,
  IPoint,
  ITemplates,
  PickClients,
  PickContractors,
  PointsResult,
  TemplateElement,
  TemplatesResult,
} from './types';

export const useClients = <Data = PickClients>(
  options?: Omit<
    UseQueryOptions<PickClients, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<PickClients, ApiError, Data>({
    queryKey: ['super', 'clients', 'list'],
    queryFn: ({ signal }) =>
      Api.get<PickClients>(`/super/clients?limit=10000`, {
        signal,
      }).then(({ data }: { data: PickClients }) =>
        data.map((item) => pick(item, ['id', 'title'])),
      ),
    ...options,
  });

export const useContractors = <Data = PickContractors>(
  options?: Omit<
    UseQueryOptions<PickContractors, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<PickContractors, ApiError, Data>({
    queryKey: ['supervisor', 'contractors', 'list'],
    queryFn: ({ signal }) =>
      Api.get<PickContractors>(`/super/contractors?limit=10000`, {
        signal,
      }).then(({ data }: { data: PickContractors }) =>
        data.map((item) => item),
      ),
    ...options,
  });

export const useEmployers = <Data = EmployersResult>(
  options?: Omit<
    UseQueryOptions<EmployersResult, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<EmployersResult, ApiError, Data>({
    queryKey: ['supervisor', 'employers', 'list'],
    queryFn: ({ signal }) =>
      Api.get<IEmployer[]>(`/super/employers?limit=10000`, {
        signal,
      }).then(
        ({ data }: { data: IEmployer[] }) =>
          data.map((employer) => {
            const id = employer.id ?? employer._id;
            const title = employer.legal_name ?? employer.user?.display_name;
            const wp = employer?.workplace?._id;
            const workplaces = (employer?.workplaces ?? [])?.map((w) => w._id);
            if (wp) workplaces.push(wp);
            return { id, title, workplaces };
          }) as unknown as EmployersResult,
      ),
    ...options,
  });

export const usePoints = <Data = PointsResult>(
  options?: Omit<
    UseQueryOptions<PointsResult, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<PointsResult, ApiError, Data>({
    queryKey: ['supervisor', 'points', 'list'],
    queryFn: ({ signal }) =>
      Api.get<IPoint[]>(`/super/workplaces?limit=10000`, {
        signal,
      }).then(({ data }: { data: IPoint[] }) => {
        const workplaces = data.map(({ id, title, ...workplace }) => ({
          id,
          title,
          ...workplace,
        }));
        const regions = uniqBy(
          data.map(({ address }) => ({
            id: address.city,
            title: address.city,
          })),
          'id',
        );

        return { workplaces, regions };
      }) as unknown as PointsResult,
    ...options,
  });

//TODO вынести в entities/templates? || entities/dictionaries?
export const useTemplates = <Data = TemplatesResult>(
  options?: Omit<
    UseQueryOptions<TemplatesResult, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<TemplatesResult, ApiError, Data>({
    queryKey: ['templates', 'list'],
    queryFn: ({ signal }) =>
      Api.get<ITemplates>(`/templates?limit=10000`, { signal }).then(
        ({ data }: { data: ITemplates }) => {
          const { basicProfessions = [], customTemplates = [] } = data;

          const customData = [...basicProfessions, ...customTemplates]
            .map((template: TemplateElement) => ({
              ...template,
              payment_per_hour: template.payment_per_hour,
              id: template.custom_name,
              title: template.custom_name,
              workplace: template.workplace?._id,
              contractor: template.workplace?.contractor,
            }))
            .filter(
              (template) => template.is_basic || !!template.workplace,
            ) as unknown as TemplatesResult;

          return customData;
        },
      ),
    ...options,
  });
