import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { RegistriesType } from '@src/entities/registries/types';

export type IStepsNames =
  | 'UploadRegistries'
  | 'ResponseUpload'
  | 'SendRegistries'
  | 'ResponseSend';

interface IUseUploadValues {
  failedFileName: string;
  setFailedFileName: (fileName: string) => void;
  step: IStepsNames;
  setStep: (step: IStepsNames) => void;
  fileName: string;
  setFileName: (fileName: string) => void;
  isFetchValidate: boolean;
  setIsFetchValidate: (isFetchValidate: boolean) => void;
  selectedCompany: string;
  setSelectedCompany: (selectedCompany: string | undefined) => void;
  selectedType: RegistriesType;
  setSelectedType: (selectedType: RegistriesType) => void;
}

export const useUploadValues = create<IUseUploadValues>()(
  persist(
    (set) => ({
      failedFileName: '',
      setFailedFileName: (failedFileName) => set({ failedFileName }),
      step: 'UploadRegistries',
      setStep: (step) => set({ step }),
      fileName: '',
      setFileName: (fileName) => set({ fileName }),
      isFetchValidate: false,
      setIsFetchValidate: (isFetchValidate) => set({ isFetchValidate }),
      selectedCompany: '',
      setSelectedCompany: (selectedCompany) => set({ selectedCompany }),
      selectedType: 'workers',
      setSelectedType: (selectedType) => set({ selectedType }),
    }),
    {
      name: 'upload-values',
      getStorage: () => sessionStorage,
    },
  ),
);
