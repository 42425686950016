/* eslint-disable no-underscore-dangle, no-mixed-operators, no-bitwise */
import { createIntl, createIntlCache } from 'react-intl';
import dayjs from 'dayjs';

const cache = createIntlCache();

export const intl = createIntl({ locale: 'ru' }, cache);

export const simulateModalLayout = (remove) => {
  if (remove === false) {
    document.body.classList.remove('modal_fake');
  } else {
    document.body.classList.add('modal_fake');
  }
};

export function FormErrorHandler(e) {
  const errors = [];
  if (e && e.response) {
    if (e.response.data && e.response.data.message) {
      if (!Array.isArray(e.response.data.message)) {
        e.response.data.message = [{ message: e.response.data.message }];
      }
      e.response.data.message.forEach((error) => {
        errors.push(error.message);
      });
    } else if (e.response.data && e.response.data.error) {
      errors.push(e.response.data.error);
    } else {
      switch (e.response.status) {
        case 401:
          errors.push('Unauthorized, try re-login');
          break;
        case 400:
          errors.push('Произошла ошибка');
          break;
        default:
      }
    }
  } else if (e && e.message) {
    errors.push(e.message);
  }

  this.setState({ isLoading: false, errors });
}

export const dateToText = (date) => {
  let text;
  const tmp = dayjs(date);
  const calendar = tmp
    .calendar(null, {
      sameDay: '[Сегодня]',
      nextDay: '[Завтра]',
      nextWeek: 'dddd',
      lastDay: 'dddd',
      lastWeek: 'dddd',
      sameElse: 'DD.MM.YYYY',
    })
    .toLowerCase();
  if (calendar.includes('today') || calendar.includes('сегодня')) {
    text = 'Сегодня';
  } else if (calendar.includes('tomorrow') || calendar.includes('завтра')) {
    text = 'Завтра';
  } else {
    text = tmp.format('D MMM').toLowerCase();
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export function pick(obj, keys) {
  return keys.reduce(
    (acc, key) => (key in obj ? { ...acc, [key]: obj[key] } : acc),
    {},
  );
}

export function uniqBy(arr, predicate) {
  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];
  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        /* eslint-disable no-unused-expressions */
        /* TODO: что этот код делает? */
        map.has(key) || map.set(key, item);
        return map;
      }, new Map())
      .values(),
  ];
}

export const formatPhoneNumber = (str) => {
  if (!str) return null;
  try {
    const tmp = `${str}`.match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
    return `+7 (${tmp[2]}) ${tmp[3]}-${tmp[4]}-${tmp[5]}`;
  } catch (e) {
    return str;
  }
};

export const humanifyId = (_id) => _id && _id.slice(-6);

export const initialPagination = () => ({
  current: 1,
  total: 1,
  pageSize: 20,
  position: ['bottomLeft'],
  showSizeChanger: false,
  columnKey: 'start',
  order: 'desc',
});

// Маппинг полей для сортировок (ключ на фронте: ключ на бэке)
// (производные поля далее ещё преобразуются на бэке)
export const columnModelMap = {
  // обычные поля:
  template: 'custom_name',
  datetime: 'start',
  // производные поля:
  factWorkers: 'workers',
  factHours: 'hours',
  factPrice: 'payments',
  address: 'address',
  employer: 'employer',
  status: 'status',
};

export const renderFullAddress = (address = {}) => {
  const fullAddress = [];
  if (address.city) fullAddress.push(address.city);
  if (address.street) fullAddress.push(address.street);
  if (address.house) fullAddress.push(address.house);
  return fullAddress.join(', ');
};

export const formatDate = (date, format = 'DD.MM.YY, HH:mm') =>
  dayjs(date).format(format);

export const dateToTextLong = (date) => {
  const string = dayjs(date).calendar(null, {
    sameDay: '[Сегодня], D MMMM',
    nextDay: '[Завтра], D MMMM',
    nextWeek: 'dddd, D MMMM',
    lastDay: 'dddd, D MMMM',
    lastWeek: 'dddd, D MMMM',
    sameElse: 'dddd, D MMMM',
  });
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatHours = (hours) => {
  const h = Math.floor(hours);
  const m = Math.round((hours * 60) % 60);
  return m ? `${h} ч. ${m} мин.` : h;
};

export const statuses = {
  new: 'Новая',
  doccheck: 'Требуются документы',
  booked: 'Забронирована',
  going: 'В пути',
  delayed: 'Задерживается',
  waiting: 'Ожидается',
  checkingin: 'Подтвердите начало',
  inprogress: 'Выполняется',
  checkingout: 'Подтвердите завершение',
  completed: 'Завершена',
  confirmed: 'Подтверждена',
  cancelled: 'Отменена',
  toolate: 'Отменена из-за опоздания',
  expired: 'Просрочена',
  failed: 'Не выполнена',
  repeat: 'Повторяется',
};

export const documents = {
  passport: 'Паспорт',
  medical: 'Медицинская книжка',
  drive: 'Водительское удостоверение',
};

export const randomString = () => `f${(~~(Math.random() * 1e8)).toString(16)}`;

export const getInitialOrderSpec = () => ({
  amount: 1,
  description: null,
  documents: [],
  finish_time: null,
  id: randomString(),
  payment_per_hour: 0,
  start: dayjs().format('YYYY-MM-DD'),
  start_time: null,
  // Не подтягиваем данные с шаблона о плате за смену, они высчитываются на фронте
  salary_per_hour: null,
  salary_per_job: null,
  payment_per_job: null,
});

export const getInitialOrder = () => ({
  id: randomString(),
  specs: [getInitialOrderSpec()],
  template: null,
});

export const formatFloat = (value, digits = 2) =>
  intl.formatNumber(value, {
    maximumFractionDigits: digits,
  });

export function peopleWhoWork(inwork) {
  return inwork.filter(
    (i) => !['cancelled', 'failed', 'doccheck', 'toolate'].includes(i.status),
  );
}

export function decl(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];

  const titleIndex =
    number % 100 > 4 && number % 100 < 20 ?
      2
    : cases[number % 10 < 5 ? number % 10 : 5];

  return titles[titleIndex];
}

export default {};
